<template>
  <button class="containerPillClick">
    <div class="containerPillClick_img">
      <div class="appointment-status" :class="data.status">
        {{ formatStatus(data.status) }}
      </div>
      <div class="datetime-container">
        <div class="appointment-date">
          {{ formatShortDate(data.scheduled_at) }}
        </div>
        <div class="appointment-datetime">
          {{ formatDateTime(data.scheduled_at) }}
        </div>
      </div>
    </div>
    <div class="containerTitleAndButtonsList">
      <div class="content-wrapper">
        <div class="containerTitleList">
          <span class="titleList--text">
            Llamada Programada
          </span>
          <span class="subtitleList--text">
            {{ data.description || "Sin descripción asignada."  }}
          </span>
        </div>
      </div>
      <div class="containerBtnsList">
        <a 
          :href="data.meeting_url" 
          target="_blank" 
          class="containerBtn--seeRigthNow"
          :disabled="!isLinkEnabled"
        >
          <v-icon class="mr-2" color="white">mdi-video</v-icon>
          <span>Unirse a la llamada</span>
        </a>
      </div>
    </div>
  </button>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isLinkEnabled() {
      return this.data.status === 'scheduled';
    }
  },
  methods: {
    formatStatus(status) {
      const statusMap = {
        'scheduled': 'Programada',
        'completed': 'Completada',
        'cancelled': 'Cancelada',
        'pending': 'Pendiente'
      };
      return statusMap[status] || status;
    },
    formatDateTime(datetime) {
      return moment(datetime).format('HH:mm');
    },
    formatShortDate(datetime) {
      return moment(datetime).format('DD MMM');
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .containerPillClick_img {
    margin-top: 1rem !important;
  }
  .containerPillClick {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .containerTitleAndButtonsList {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .containerBtnsList {
    display: flex !important;
    gap: 1rem !important;
    margin-top: 1rem !important;
  }
  .titleList--text,
  .subtitleList--text {
    max-width: 215px !important;
  }
}

.content-wrapper {
  flex: 1;
}

.containerIconBtn--seeRigthNow {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.containerBtn--seeRigthNow {
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.containerBtn--seeRigthNow[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.containerBtnsList {
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 1rem;
}

.subtitleList--text {
  text-align: left;
  font: normal normal 300 12px/18px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-top: 4px;
}

.titleList--text {
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
}

.containerTitleList {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.containerPillClick {
  width: 100%;
  display: flex;
  border: 1px solid var(--unnamed-color-dfdfdf);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  opacity: 1;
}

.containerTitleAndButtonsList {
  display: flex;
  padding: 21px;
  flex-direction: column;
  width: 100%;
  min-height: 145px;
}

.containerPillClick_img {
  border-radius: 8px 0px 0px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 145px;
  background: #f8f9fa;
  margin-top: 0px !important;
  gap: 12px;
}

.datetime-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.appointment-date {
  font-size: 16px;
  font-weight: 500;
  color: #101820;
  text-transform: uppercase;
}

.appointment-status {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 16px;
}

.appointment-status.scheduled {
  background-color: #20d489;
  color: white;
}

.appointment-status.completed {
  background-color: #7239ea;
  color: white;
}

.appointment-status.cancelled {
  background-color: #f1416c;
  color: white;
}

.appointment-status.pending {
  background-color: #ffc700;
  color: #ffffff;
}

.appointment-datetime {
  font-size: 24px;
  font-weight: bold;
  color: #101820;
}
</style> 