<template>
  <bg-new>
   <!-- 
    <div
      v-if="showAdvHeader || typeof showAdvHeader === 'string'"
      class="advupdatePlan"
    >
     
      
      <div>
        <span>{{
          typeof showAdvHeader === "string"
            ? showAdvHeader
            : "Dispones de 3 DÍAS GRATIS en Pilsa por tu Plan Gratuito"
        }}</span>
        <button
          @click="$router.push({ name: 'checkout_auth' })"
          class="btn-seeRigthNow-2"
        >
          <span>Actualizar Plan</span>
        </button>
      </div>
    
    </div>
      -->

    <slot name="header"></slot>
    <div
      class="container-dashboard"
      :style="{ top: showAdvHeader ? '200px' : '136px' }"
    >
      <div class="d-flex classContainerBodyDashboard">
        <sidebar-left-new :level-user-percentage="levelUserPercentage" />
        <slot name="body"></slot>
      </div>

      <div class="footer">
        <div class="d-flex">
          <v-icon size="28">mdi-facebook</v-icon>
          <v-icon class="ml-3" size="28">mdi-instagram</v-icon>
        </div>
        <div>
          <a class="footer_link" href="#" target="_blank"
            >Política de privacidad. Términos y condiciones</a
          >
        </div>
        <div class="d-flex">
          <div>
            <span class="footer_text">Email:</span>
            <span class="ml-1">
              <a class="footer_link" href="#" target="_blank"
                >sales@pilsa.io</a
              ></span
            >
          </div>
          <div>
            <span class="footer_text">-Central:</span>
            <span class="ml-1">
              <a class="footer_link" href="#" target="_blank"
                >+1 (424) 768-1780</a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </bg-new>
</template>

<script>
import SideBarLeft from "./Dashboard/SideBarLeft.vue";
import BgGray from "./BgGray.vue";

export default {
  components: {
    "sidebar-left-new": SideBarLeft,
    "bg-new": BgGray,
  },
  props: {
    levelUserPercentage: {
      type: String,
      default: "0",
    },
    showAdvHeader: {
      type: Boolean || String,
      default: false,
    },
  },
  data() {
    return {
      plan: [],
    };
  },

  methods: {},
};
</script>

<style scoped>
.footer {
  display: flex;
  margin-left: -140px;
  width: calc(100% +140px);
  padding: 1rem;
  /* background: white; */
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .footer {
    /* background-color: red !important; */
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 0 !important;
  }
  .classContainerBodyDashboard {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container-dashboard {
    margin-left: 0 !important;
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .advupdatePlan {
    padding: 1rem !important;
    height: auto !important;
  }
}
@media (max-width: 500px) {
  .classContainerBodyDashboard {
    margin-top: 2rem !important;
  }
}

.btn-seeRigthNow-2 {
  text-align: center;
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: 0px;
  color: hsl(0, 0%, 100%);
  padding: 4px 10px;
  margin-left: 1rem;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
}
.advupdatePlan {
  top: 0;
  z-index: 1000;
  background: transparent
    linear-gradient(91deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%) 0% 0%
    no-repeat padding-box;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: normal normal normal 17px/40px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.footer_text {
  text-align: right;
  font: normal normal 300 12px/12px Poppins;
  letter-spacing: 0px;
  color: #3e4a56;
}
.footer_link {
  text-align: right;
  text-decoration: underline;
  font: normal normal 300 12px/18px Poppins;
  letter-spacing: 0px;
  color: #3e4a56;
}

.container-dashboard {
  position: absolute;
  /* top: 136px; */
  /* background: red; */
  width: calc(100% - 140px);
  margin-bottom: 2rem;
  /* margin: 0 2rem; */
  /* padding: 0 5rem; */
  margin-left: 140px;
  display: flex;
  flex-direction: column;
  z-index: 100;
}
</style>
