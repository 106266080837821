<template>
  <section>
    <ValidationObserver ref="obs" >
      <div class="ct-loading" v-if="loadingCode"></div>
      
      <section class="setter">
        <div class="img-left">
          <p></p>
           <v-img cover src="https://s3.amazonaws.com/assets.pilsala.com/assets/asian_woman.jpg"></v-img>
        </div>
        
        <div class="div-rigth">
          <section class="section-rigth">
            <div>
              <v-img cover :src="dynamicImageUrl" class="max-w-300"></v-img>
            </div>
            
            <section class="d-flex flex-column min-w-250">
              <p class="title_recover_password">¡Prepárate para Empezar!</p>
              <p class="subtitle_recover_password">
                Define tu contraseña y repárate para sumergirte en un viaje de aprendizaje emocionante.
              </p>


              <div class="mt--2">
                <p class="p13">Crear contraseña</p>
                <ValidationProvider
                   name="contraseña"
                  rules="required|confirmed:confirmation"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="password"
                    v-model="password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>

              <div class="mt--1">
                <p class="p13">Confirmar Contraseña</p>
                <ValidationProvider
                  name="confirmar contraseña"
                  rules="required"
                  vid="confirmation"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @keyup.enter="passes(HandlerSavePassword)"
                    type="password"
                    v-model="confirm_password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>

              <v-btn
                :disabled="!isPasswordValid"
                :loading="loadingReset"
                @click="HandlerSavePassword"
                color="#101820"
                rounded
                class="white--text font-light py-2 text-capitalize"
              
              >
                Guardar
              </v-btn>
              
              <div class="text-center font-light mt-1 mb-2">
                <span>o bien</span>
              </div>


              <v-btn
                color="#f5f5f5"
                rounded
                class="gray--text bg-white py-3 text-none mb-2"
                @click="HandlerRedirectSocialAccount('google')"
                >
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>

                  <span class="ml-2"> Conecta tu cuenta de Google </span>
              </v-btn>

              <v-btn
                color="#f5f5f5"
                rounded
                class="gray--text bg-white py-3 text-none mb-4"
                @click="HandlerRedirectSocialAccount('linkedin')"
                >
                  <svg height="24" viewBox="0 0 72 72" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/><path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/></g></svg>
                  <span class="ml-2"> Continuar con LinkedIn </span>
              </v-btn>
             

            </section>
          </section>

        
        </div>
      </section>
    </ValidationObserver>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isValid: false,
      authToken: "",
      token: "",
      isNotAccept: true,
      loadingRecovery: false,
      phone_number: "",
      loadingCode: true,
      countGetCode: 0,
      intervalId: null,
      loadingReset: false,
      countries: [],
      country_id: "",
      phone: "",
      canChange: false,
      code_recovery: "",
      canReset: false,
      password: "",
      confirm_password: "",
      dynamicImageUrl: null,
      errorMessage: "",
      subdomain: "",
    };
  },
  computed: {
    isPasswordValid() {
      return this.password !== '' && this.password === this.confirm_password;
    }
  },
  watch: {},
  created() {

    this.subdomain = window.location.hostname.split('.')[0];
    const brand =  this.subdomain &&  this.subdomain !== 'localhost' ?  this.subdomain : 'app';
    this.dynamicImageUrl = `https://s3.amazonaws.com/assets.pilsala.com/brand_logos/${brand}.png`;
   
  },

  async mounted() {

    this.authToken = this.$route.query.authToken;
    this.errorMessage = this.$route.query.error_message;

    if(this.errorMessage) {
      this.$snotify.error(this.errorMessage, "¡Error!");  
    }

    if(this.authToken) {
      await this.$store.dispatch("auth/TOKEN_VALIDATE",this.authToken);
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.$snotify.success("Cuenta conectada", "¡Bienvenido!");
      this.$router.push({ name: "profile" });
      return; 
    }
    
    console.log('test');
    this.token = this.$route.query.token;
    await this.HandlerConfrimCode(this.token);
    this.loadingCode = false;
   
  },
  methods: {
    close() {
      this.$emit("dialog:change", false);
    },
    async HandlerConfrimCode(code) {
      try {
        this.loadingCode = true;
        const response = await this.$store.dispatch("auth/CONFIRM_CODE", {
          code
        });
        this.phone_number =  response.data.data.whatsapp_ccounts.phone_number;
        this.country_id = response.data.data.whatsapp_ccounts.country_id;
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error("Ha ocurrido un error con tu solicitud", "¡Error!");
        this.$router.push({ name: 'Login' });
      } finally {
        this.loadingCode = false;
      }
    },

    HandlerRedirectSocialAccount (provider) {
       window.location.href = `https://campus.pilsa.io/auth/${provider}/redirect?password_setup_token=${this.token}`;
    },

    async HandlerSavePassword() {
      try {
        this.loadingReset = true;
        const request = {
          country_id: this.country_id,
          phone_number: this.phone_number,
          password: this.password,
          password_confirmation: this.confirm_password,
          token: this.token
        };
        
        const responseReset = await this.$store.dispatch("auth/RESET_PASSWORD", request);
    
        window.location.href = `${window.location.origin}/login?data_ref=${responseReset.data.encryptedId}`;
        
      } catch (error) {
        console.log(error.response);
        this.$snotify.error("Error al defnir la contraseña", "¡Error!");
      } finally {
        this.loadingReset = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .img-left {
    display: none !important;
  }
  .setter {
    width: 100% !important;
    /* width: 100vw !important; */
    /* background-color: red !important; */
  }
  .div-rigth {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}

.w-100 {
  width: 100%;
}
.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  position: absolute;
  bottom: 0;
}

.div-copyright {
        background-color: #f8f8f8;
        text-align: center;
        padding: 10px;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1000;
}

.p13 {
  font-size: 13px;
}
.p15 {
  font: normal normal normal 15px/21px Poppins;
  font-size: 15px;
}
.mt--2 {
  margin-top: -10px;
}
.section-rigth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.p-text {
  margin-top: 3rem;
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.setter {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}
.img-left {
  width: 50vw;
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.div-rigth {
  position: relative;
  width: 50vw;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: initial;
}
.d-flex {
  display: flex;
}
.max-width {
  max-width: 250px;
}

.subtitle_recover_password {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-bottom: 4rem !important;
}
.title_recover_password {
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.recoverPasswordContainer_putCode2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.classBtnReqCode {
  color: white;
  border-radius: 32px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.classConYouReq_text {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0076ff;
}
.recoverPasswordContainer_putCode {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.closeWrelative {
  position: absolute;
  top: 0;
  margin: 3rem;
  right: 0;
}
.classContainerBtn {
  margin-bottom: 10rem;
}
.recoverPasswordContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subtitle_recover_password {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-bottom: 4rem !important;
}
.title_recover_password {
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.custom-color {
  color: #929292;
}

.ct-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #ffffff91;
}

</style>
