<template>
  <v-dialog transition="slide-x-reverse-transition" v-model="active" max-width="600">
    <v-card class="questions-dialog">
      <div class="d-flex justify-space-between px-5 py-5" style="background-color: white;">
        <h3 style="font-weight: bold;">Preguntas</h3>
        <v-btn @click="close" class="mt-1 mr-4 mr-md-0" x-small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>


    
      <v-tabs
        v-model="activeTab"
  
        style="    flex: none;"
      > 
        <v-tab>
          <span class="pillsPendingTitle" style="text-transform: capitalize;margin-right: 10px;">Mis Preguntas</span>
          ({{ myQuestions.length }})
        </v-tab>
        <v-tab>
          <span class="pillsPendingTitle" style="text-transform: capitalize;margin-right: 10px;">Preguntas Públicas</span>
          ({{ publicQuestions.length }})
        </v-tab>
      </v-tabs>

      <v-divider class="mb-0"></v-divider>

      <!-- Tab Mis Preguntas -->
      <div v-if="activeTab === 0" class="questions-content">
        <div class="pa-4">
            <div v-if="loading" class="text-center py-4">
                <v-progress-circular
                indeterminate
                color="primary"
                class="mr-2"
                ></v-progress-circular>
                <span class="loading-text">Cargando preguntas...</span>
            </div>

            <div v-else-if="myQuestions.length === 0" class="text-center py-8">
                <p class="text-muted">No tienes preguntas aún</p>
            </div>

            <div v-else>
                <div v-for="(question, index) in myQuestions" :key="index" class="question-item mb-4">
                    <div class="d-flex justify-space-between align-center">
                        <div class="question-text">{{ question.question }}</div>
                        <v-chip
                        :color="question.is_public ? 'primary' : 'grey'"
                        small
                        class="ml-2"
                        >
                        {{ question.is_public ? 'Pública' : 'Privada' }}
                        </v-chip>
                    </div>
                    <div class="question-meta mt-2">
                        <span class="text-muted">{{ formatDate(question.created_at) }}</span>
                    </div>
                    
                    <!-- Archivos adjuntos de la pregunta -->
                    <div v-if="question.attachments && question.attachments.length > 0" class="attachments-section mt-2">
                        <div v-for="(attachment, aIndex) in question.attachments" :key="aIndex" class="attachment-item">
                            <v-icon small class="mr-2">mdi-paperclip</v-icon>
                            <a :href="attachment.file_path" target="_blank" class="attachment-link">
                                {{ attachment.original_name }}
                            </a>
                        </div>
                    </div>
                    
                    <!-- Respuestas -->
                    <div v-if="question.responses && question.responses.length > 0" class="responses-section mt-3">
                        <div v-for="(response, rIndex) in question.responses" :key="rIndex" class="response-item">
                            <div class="d-flex align-center mb-2">
                                <v-avatar size="24" class="mr-2">
                                    <img :src="response.user.profile_photo_url" :alt="response.user.name">
                                </v-avatar>
                                <strong class="response-author">{{ response.user.name }}</strong>
                            </div>
                            <div class="response-text">{{ response.response }}</div>
                            <div class="response-date text-muted mt-1">{{ formatDate(response.created_at) }}</div>
                            
                            <!-- Archivos adjuntos de la respuesta -->
                            <div v-if="response.attachments && response.attachments.length > 0" class="attachments-section mt-2">
                                <div v-for="(attachment, aIndex) in response.attachments" :key="aIndex" class="attachment-item">
                                    <v-icon small class="mr-2">mdi-paperclip</v-icon>
                                    <a :href="attachment.file_path" target="_blank" class="attachment-link">
                                        {{ attachment.original_name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!-- Tab Preguntas Públicas -->
      <div v-if="activeTab === 1" class="questions-content">
        <div class="pa-4">
            <div v-if="loading" class="text-center py-4">
                <v-progress-circular
                indeterminate
                color="primary"
                class="mr-2"
                ></v-progress-circular>
                <span class="loading-text">Cargando preguntas...</span>
            </div>
        
            <div v-else-if="publicQuestions.length === 0" class="text-center py-8">
                <p class="text-muted">No hay preguntas públicas aún</p>
            </div>
            <div v-else>
                <div v-for="(question, index) in publicQuestions" :key="index" class="question-item mb-4">
                    <div class="d-flex justify-space-between align-center">
                        <div class="d-flex align-center">
                            <div class="question-text">{{ question.question }}</div>
                        </div>
                        <v-chip color="primary" small class="ml-2">Pública</v-chip>
                    </div>
                    <div class="question-meta mt-2 d-flex align-center">
                        <span class="text-muted">Preguntado por {{ question.learner.full_name }}</span>
                        <v-divider vertical class="mx-2"></v-divider>
                        <span class="text-muted">{{ formatDate(question.created_at) }}</span>
                    </div>
                    
                    <!-- Archivos adjuntos de la pregunta -->
                    <div v-if="question.attachments && question.attachments.length > 0" class="attachments-section mt-2">
                        <div v-for="(attachment, aIndex) in question.attachments" :key="aIndex" class="attachment-item">
                            <v-icon small class="mr-2">mdi-paperclip</v-icon>
                            <a :href="attachment.file_path" target="_blank" class="attachment-link">
                                {{ attachment.original_name }}
                            </a>
                        </div>
                    </div>
                    
                    <!-- Respuestas -->
                    <div v-if="question.responses && question.responses.length > 0" class="responses-section mt-3">
                        <div v-for="(response, rIndex) in question.responses" :key="rIndex" class="response-item">
                            <div class="d-flex align-center mb-2">
                                <v-avatar size="24" class="mr-2">
                                    <img :src="response.user.profile_photo_url" :alt="response.user.name">
                                </v-avatar>
                                <strong class="response-author">{{ response.user.name }}</strong>
                            </div>
                            <div class="response-text">{{ response.response }}</div>
                            <div class="response-date text-muted mt-1">{{ formatDate(response.created_at) }}</div>
                            
                            <!-- Archivos adjuntos de la respuesta -->
                            <div v-if="response.attachments && response.attachments.length > 0" class="attachments-section mt-2">
                                <div v-for="(attachment, aIndex) in response.attachments" :key="aIndex" class="attachment-item">
                                    <v-icon small class="mr-2">mdi-paperclip</v-icon>
                                    <a :href="attachment.file_path" target="_blank" class="attachment-link">
                                        {{ attachment.original_name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!-- Formulario para nueva pregunta -->
      <div class="pa-4">
        <div class="d-flex justify-end mb-4">
          <v-switch
            v-model="isPublic"
            label="Hacer pública"
            hide-details
            class="mt-0"
            dense
            :disabled="loadingSubmit"
          ></v-switch>
        </div>
        <v-textarea
          v-model="newQuestion"
          label="Escribe tu pregunta"
          rows="2"
          auto-grow
          hide-details
          class="mb-4"
          outlined
          dense
          :disabled="loadingSubmit"
        ></v-textarea>

        <!-- Sección de archivos adjuntos -->
        <div class="mb-4">
          <input
            type="file"
            ref="fileInput"
            multiple
            accept="*/*"
            style="display: none"
            @change="handleFileChange"
          >
          <v-btn
            outlined
            color="grey"
            class="file-upload-btn"
            :disabled="loadingSubmit"
            @click="$refs.fileInput.click()"
          >
            <v-icon left>mdi-paperclip</v-icon>
            Adjuntar archivos
          </v-btn>
          
          <!-- Lista de archivos seleccionados -->
          <div v-if="selectedFiles.length > 0" class="selected-files mt-2">
            <div v-for="(file, index) in selectedFiles" :key="index" class="selected-file-item">
              <v-icon small class="mr-2">mdi-file</v-icon>
              <span class="file-name">{{ file.name }}</span>
              <v-btn
                x-small
                icon
                class="ml-2"
                @click="removeFile(index)"
                :disabled="loadingSubmit"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <v-btn
          color="primary"
          block
          :disabled="!newQuestion.trim() || loadingSubmit"
          @click="addQuestion"
          class="containerBtn--seeRigthNow"
        >
          <template v-if="loadingSubmit">
            <v-progress-circular
              indeterminate
              size="20"
              width="2"
              color="white"
              class="mr-2"
            ></v-progress-circular>
            Enviando...
          </template>
          <template v-else>
            <v-icon class="mr-2" color="white">mdi-send</v-icon>
            <span>Enviar Pregunta</span>
          </template>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
moment.locale('es');

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: true,
    },
    lessonId: {
      type: [Number, String],
      required: true
    },
    courseId: {
      type: [Number, String], 
      required: true
    }
  },

  data() {
    return {
      activeTab: 0,
      newQuestion: '',
      isPublic: false,
      myQuestions: [],
      publicQuestions: [],
      loading: false,
      loadingSubmit: false,
      selectedFiles: []
    };
  },

  watch: {
    active(newVal) {
      if (newVal) {
        this.loadQuestions();
      }
    },
    activeTab() {
      this.loadQuestions();
      this.isPublic = this.activeTab === 1;
      this.newQuestion = '';
    }
  },

  methods: {
    close() {
      this.$emit('dialog:change', false);
    },

    async loadQuestions() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch('profile/LIST_LESSONS_QUESTION', {
          lesson_id: this.lessonId,
          is_public: this.activeTab === 1 ? 1 : 0
        });
        
        if (this.activeTab === 0) {
          this.myQuestions = response.data.data;
        } else {
          this.publicQuestions = response.data.data;
        }


        
      } catch (error) {
        console.error('Error cargando preguntas:', error);
      } finally {
        this.loading = false;
      }
    },

    async addQuestion() {
      if (!this.newQuestion.trim() || this.loadingSubmit) return;

      try {
        this.loadingSubmit = true;
        
        // Crear FormData para enviar archivos
        const formData = new FormData();
        formData.append('lesson_id', this.lessonId);
        formData.append('course_id', this.courseId);
        formData.append('question', this.newQuestion);
        formData.append('is_public', this.isPublic ? 1 : 0);

        // Agregar archivos al FormData
        this.selectedFiles.forEach((file, index) => {
          formData.append(`files[${index}]`, file);
        });

        const response = await this.$store.dispatch('profile/CREATE_LESSONS_QUESTION', formData);

        // Agregar la nueva pregunta a la lista correspondiente
        const newQuestion = response.data;
        if (this.isPublic) {
          this.publicQuestions.unshift({
            ...newQuestion,
            responses: [],
            learner: {
                full_name: "Mi"
            } 
          });
        }
        this.myQuestions.unshift(newQuestion);

        // Limpiar formulario
        this.newQuestion = '';
        this.selectedFiles = [];
        this.isPublic = this.activeTab === 1;
        this.$snotify.success('Pregunta enviada exitosamente');
      } catch (error) {
        console.error('Error enviando pregunta:', error);
        this.$snotify.error('Error al enviar la pregunta');
      } finally {
        this.loadingSubmit = false;
      }
    },

    handleFileChange(event) {
      const files = Array.from(event.target.files);
      this.selectedFiles = [...this.selectedFiles, ...files];
    },

    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },

    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    }
  }
};
</script>

<style scoped>
.questions-dialog {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.questions-content {
  flex: 1;
  overflow-y: auto;
}

.question-item {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

.question-text {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.question-meta {
  font-size: 12px;
  color: #666;
}

.text-muted {
  color: #6c757d;
}

.pillsPendingTitle {
  text-align: left;
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}

.containerBtn--seeRigthNow {
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.containerBtn--seeRigthNow[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.responses-section {
  border-left: 2px solid #e0e0e0;
  margin-left: 12px;
  padding-left: 12px;
  margin-top: 16px;
}

.response-item {
  background: #ffffff;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.response-author {
  font-size: 13px;
  color: #333;
}

.response-text {
  font-size: 14px;
  color: #444;
  line-height: 1.4;
}

.response-date {
  font-size: 11px;
  color: #666;
}

.attachment-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background: #f5f5f5;
    border-radius: 4px;
    margin-top: 4px;
}

.attachment-link {
    color: #0076ff;
    text-decoration: none;
    font-size: 12px;
    word-break: break-all;
}

.attachment-link:hover {
    text-decoration: underline;
}

.selected-files {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
}

.selected-file-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: white;
  border-radius: 4px;
  margin-bottom: 4px;
  font-size: 12px;
}

.file-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-upload-btn {
  width: 100%;
  text-transform: none;
  border: 1px solid #ced4da;
  background-color: #f8f9fa;
  color: #495057;
  font-size: 14px;
  height: 40px;
}

.file-upload-btn:hover {
  background-color: #e9ecef;
  border-color: #0076ff;
  color: #0076ff;
}

.file-upload-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style> 