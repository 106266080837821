<template>
  <div>
    <header class="container-app-bar">
      <div class="d-flex child-container">
        <div class="d-flex container-app-bar-rigth ">
          <div class="container-logo">
            <v-img
              @click="HandlerRouter"
              style="cursor: pointer ;"
              :src="dynamicImageUrl"
              alt="Logo Pilsa"
              class="max-w-300"
            />
          </div>
          <span class="containerDinamicSubHeader"
            >{{ getAuthUser.modules.name }}
            {{
              typeof validateAds() === "string"
                ? validateAds()
                : validateAds() === true
                ? "| Plan Gratuito"
                : ""
            }}</span
          >
        </div>
        <div>
          <div class="d-none d-sm-none d-md-flex mt-5">
            <div v-if="!isAuth">
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="HandlerGoLanding"
                  :color="hover ? '#061F42' : '#E05492'"
                  class="text-capitalize ml-10"
                  dark
                  rounded
                >
                  Solicita una Demo
                </v-btn>
              </v-hover>
            </div>
            <div class="d-flex" v-else>
              <div class="d-flex containerToolbar-user-Notifications">
                <v-btn
                  @click="showNotification = !showNotification"
                  icon
                  class="mx-2"
                >
                  <v-icon
                    :class="
                      hasNotifications
                        ? 'animate__animated animate__pulse animate__infinite'
                        : ''
                    "
                  >
                    <!-- :color="hasNotifications ? '#0DB2D2' : 'black'" -->
                    <!-- {{ hasNotifications ? "mdi-bell-ring" : "mdi-bell-outline" }} -->
                    mdi-bell-outline
                  </v-icon>
                </v-btn>
                <!-- v-show="new_notifications.length > 0" -->
                <div 
                  v-if="new_notifications.length > 0"
                  class="counterNewNotifications_toolbar"
                >
                  <span class="counterStylesNotifications">{{
                    new_notifications.length
                  }}</span>
                </div>
                <button
                  class="mr-5"
                  @click="showNotification = !showNotification"
                >
                  <v-icon>
                    mdi-chevron-down
                  </v-icon>
                </button>
              </div>
              <v-btn @click="$router.push({ name: 'profile' })" icon>
                <v-avatar color="white" size="40">
                  <!-- <v-icon
                    v-if="getAuthUser.whatsapp_account_image_profiles == null"
                    color="primary"
                    >mdi-account</v-icon
                  > -->
                  <img
                    v-if="getAuthUser.whatsapp_account_image_profiles == null"
                    src="@/assets/img/userDefault.svg"
                    alt="userDefault"
                  />
                  <img
                    v-else
                    :src="
                      getAuthUser.whatsapp_account_image_profiles.file_upload
                    "
                    alt="profile avatar"
                  />
                </v-avatar>
              </v-btn>
              <button class="ml-2" @click="dialogUser = !dialogUser">
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </button>
              <v-dialog
                v-model="dialogUser"
                content-class="elevation-0 "
                hide-overlay
              >
                <div class="dialogabc">
                  <div class="d-flex px-5 py-5 algcenter">
                    <v-avatar color="white" size="60">
                      <!-- <v-icon
                    v-if="getAuthUser.whatsapp_account_image_profiles == null"
                    color="primary"
                    >mdi-account</v-icon
                  > -->
                      <img
                        v-if="
                          getAuthUser.whatsapp_account_image_profiles == null
                        "
                        src="@/assets/img/userDefault.svg"
                        alt="userDefault"
                      />
                      <img
                        v-else
                        :src="
                          getAuthUser.whatsapp_account_image_profiles
                            .file_upload
                        "
                        alt="profile avatar"
                      />
                    </v-avatar>
                    <div class="d-flex flex-column ml-3">
                      <span class="nameDialog">{{
                        `${getAuthUser.name} ${getAuthUser.last_name}`
                      }}</span>
                      <span class="numberUserDialog">{{
                        `${getAuthUser.countries.phone_code} ${getAuthUser.phone_number}`
                      }}</span>
                    </div>
                  </div>
                  <v-divider />
                  <button @click="dialogLogOut = true">
                    <div class="d-flex px-4 py-4 algcenter  ">
                      <v-icon>mdi-logout</v-icon>
                      <span class="logoutText ml-3">Cerrar Sesión</span>
                    </div>
                  </button>
                </div>
              </v-dialog>
              <!-- <v-btn
                :loading="loading"
                @click="HandlerLogout"
                color="#696969"
                text
              >
                Salir
              </v-btn> -->
            </div>
          </div>
          <v-app-bar-nav-icon
            @click="drawer = !drawer"
            class="d-flex d-sm-flex d-md-none"
          ></v-app-bar-nav-icon>
          <!-- <v-navigation-drawer
            v-model="drawer"
            bottom
            color="transparent"
            fixed
            height="auto"
            overlay-color="secondary"
            overlay-opacity=".0"
            temporary
            v-bind="$attrs"
            v-on="$listeners"
          >
            <div></div>
            <v-list class="perroAA" color="white" shaped>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in drawerItems" :key="i">
                  <v-list-item-content>
                    <v-btn
                      v-if="item.func == true"
                      color="#2696A6"
                      text
                      @click="HandlerLogout(item)"
                      class="font-medium text-capitalize"
                      >{{ item.text }}</v-btn
                    >
                    <v-btn
                      v-if="item.func == false"
                      color="#2696A6"
                      text
                      @click="HandlerRoute(item.href, item.value)"
                      class="font-medium text-capitalize"
                      >{{ item.text }}</v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer> -->
          <v-dialog transition="slide-x-reverse-transition" v-model="drawer">
            <v-list class="perroAA" color="white" shaped>
              <div v-if="isAuth">
                <div
                  class="d-flex justify-space-between px-5 py-5 classMenuProfileA"
                  style="background-color: white;"
                >
                  <v-avatar color="white" size="60">
                    <!-- <v-icon
                    v-if="getAuthUser.whatsapp_account_image_profiles == null"
                    color="primary"
                    >mdi-account</v-icon
                  > -->
                    <img
                      v-if="getAuthUser.whatsapp_account_image_profiles == null"
                      src="@/assets/img/userDefault.svg"
                      alt="userDefault"
                    />
                    <img
                      v-else
                      :src="
                        getAuthUser.whatsapp_account_image_profiles.file_upload
                      "
                      alt="profile avatar"
                    />
                  </v-avatar>
                  <h3 style="font-weight: bold;">
                    {{ getAuthUser.modules.name }}
                  </h3>
                  <span>
                    {{
                      `${getAuthUser.countries.phone_code} ${getAuthUser.phone_number}`
                    }}
                  </span>

                  <!-- <button
                  @click="drawer = false"
                  type="button"
                  class="mt-1 mr-4 mr-md-0 v-btn v-btn--icon v-btn--round theme--light v-size--x-small"
                >
                  <span class="v-btn__content"
                    ><i
                      aria-hidden="true"
                      class="v-icon notranslate mdi mdi-close theme--light"
                    ></i
                  ></span>
                </button> -->
                </div>
                <v-divider></v-divider>
              </div>
              <v-list-item-group color="black">
                <v-list-item v-for="(item, i) in drawerItems" :key="i">
                  <v-list-item-content>
                    <v-btn
                      v-if="item.func == true"
                      color="black"
                      text
                      @click="HandlerLogout(item)"
                      class="font-medium text-capitalize"
                      >{{ item.text }}</v-btn
                    >
                    <v-btn
                      v-if="item.func == false"
                      color="black"
                      text
                      @click="HandlerRoute(item.href, item.value)"
                      class="font-medium text-capitalize"
                      >{{ item.text }}</v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-dialog>
        </div>
      </div>
    </header>
    <v-dialog width="376" dark v-model="dialogLogOut">
      <div class="v-dialog-logout-container">
        <v-icon
          class="v-dialog-logout-container_v-dialog-logout-container"
          size="63"
          color="#0076FF"
          >mdi-logout</v-icon
        >
        <p class="v-dialog-text">¿Seguro que quieres Cerrar Sesión?</p>

        <br />
        <div class="btnsContainerDialog">
          <button class="v-dialog__btn-yes" @click="HandlerLogout('salir')">Sí</button>
          <button class="v-dialog__btn-no" @click="dialogLogOut = false">
            No
          </button>
        </div>
      </div>
    </v-dialog>
    <show-notification
      v-if="showNotification"
      :active="showNotification"
      @dialog:change="HandlerNotificationResponse"
    />
  </div>
</template>

<script>
import ShowNotifications from "../Profile/Notifications/showNotifications";
export default {
  components: {
    "show-notification": ShowNotifications,
  },

  data() {
    return {
      drawer: false,
      dialogLogOut: false,
      loading: false,
      showNotification: false,
      dialogUser: false,
      dynamicImageUrl: null,
    };
  },

  created() {
    const subdomain = window.location.hostname.split('.')[0];
    const brand = subdomain && subdomain !== 'localhost' ? subdomain : 'app';
    this.dynamicImageUrl = `https://s3.amazonaws.com/assets.pilsala.com/brand_logos/${brand}.png`;
  },

  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    getAuthUser() {
      return this.$store.getters["auth/AUTH_USER"];
    },

    hasNotifications() {
      return this.$store.getters["profile/HAS_NOTIFICATIONS"];
    },
    new_notifications() {
      return this.$store.getters["profile/GET_NEW_NOTIFICATION"];
    },

    drawerItems() {
      const isAuth = this.$store.getters["auth/AUTHENTICATED"];
      let items;
      if (isAuth) {
        items = [
          { text: "salir", func: true },
          { text: "perfil", func: true },
          { text: "notificaciones", func: true },
          { text: "panel", func: true },
          { text: "favoritos", func: true },
          { text: "mi recorrido", func: true },
        ];
      } else {
        items = [{ text: "Solicita una Demo", func: false }];
      }
      return items;
    },
  },

  methods: {
    validateAds() {
      if (this.getAuthUser.company.length > 0)
        return '| '+this.getAuthUser.company[0].business_name;

      return this.getAuthUser.plans.length === 0 ? true : false;
    },
    HandlerRoute(name, value) {
      this.$router.push({ name: name });
      if (value == "price") {
        window.location.href = "#price";
      }
    },

    HandlerRouter() {
      this.$router.push({ name: "profile" });
    },

    async HandlerLogout(item) {
      console.log(item.text);
      try {
        switch (item.text) {
          case "salir":
            this.loading = true;
              await this.$store.dispatch("auth/LOGOUT");
              window.location.href = window.location.origin + '/login';
              break;
          case "panel":
            this.$router.push({ name: "profile" });
            break;
          case "notificaciones":
            this.drawer = !this.drawer;
            this.showNotification = !this.showNotification;
            break;
          case "perfil":
            this.$router.push({ name: "my_profile" });
            break;
          case "favoritos":
            this.$router.push({ name: "my_favorites" });
            break;
          case "mi recorrido":
            this.$router.push({ name: "my_way" });
            break;
          default:
            this.loading = true;
            await this.$store.dispatch("auth/LOGOUT");
            window.location.href = window.location.origin + '/login';
            break;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerGoLanding() {
      window.location = "https://emp.pilsa.io/";
    },

    HandlerNotificationResponse(res) {
      this.showNotification = res;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
  .child-container {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .dialogabc {
    right: 45px !important;
  }
  .container-app-bar-rigth {
    flex-direction: column !important;
  }
  .containerDinamicSubHeader {
    margin-top: 0.5rem;
    display: none !important;
  }
}
.classMenuProfileA {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.perroAA {
  position: absolute;
  top: 0;
  width: 320px;
  right: 0;
  height: 100vh;
  overflow: auto;
  background: #f6f6f6 !important;
  padding: 0 !important;
  color: #000;
}
.btnsContainerDialog {
  display: flex;
  justify-content: space-between;
}
.v-dialog__btn-yes {
  text-align: center;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: -0.15px;
  color: #ffffff;
  padding: 15px 70px;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 25px;
}

.v-dialog__btn-no {
  text-align: center;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: -0.15px;
  padding: 15px 70px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 25px;
}
.v-dialog-logout-container_v-dialog-logout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* display: flex;
  flex-direction: column; */
}
.v-dialog-text {
  text-align: center;
  font: normal normal 300 16px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  margin: 1rem;
  margin-bottom: 2rem;
}
.v-dialog-logout-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 12px #6e6e7729;
  padding: 1rem;
  border-radius: 12px;

  width: 376px;
  height: 285px;
  overflow: hidden;
}
.bg_bray_ab {
  background: #00000035 0% 0% no-repeat padding-box;
}
.counterStylesNotifications {
  font-size: 13px;
}
.containerToolbar-user-Notifications {
  justify-content: center;
  align-items: center;
}
.counterNewNotifications_toolbar {
  width: 1rem;
  height: 1rem;
  padding: 1rem;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.logoutText {
  text-align: left;
  font: normal normal normal 13px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.algcenter {
  align-items: center;
}
.nameDialog {
  text-align: left;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.numberUserDialog {
  text-align: left;
  font: normal normal normal 13px/21px Poppins;
  letter-spacing: 0px;
  color: #8a98a5;
}
.dialogabc {
  position: absolute;
  background-color: white;
  top: 100px;
  right: 145px;
  /* height: 144px; */
  width: 289px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000029;
  border-radius: 8px;
}
.container-logo {
  width: 120px;
  margin-right: 1rem;
}
.container-app-bar-rigth {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-app-bar {
  height: 300px;
  background: #e3eff8 0% 0% no-repeat padding-box !important;
  box-shadow: none !important;
  display: flex;
  align-items: flex-start;
  width: 100% !important;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  /* background-color: red !important; */
}
.child-container {
  margin-left: 140px;
  margin-right: 140px;
  margin-top: 24px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}
</style>
