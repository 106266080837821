<template>
  <v-responsive>
    <dashboard-new
      :level-user-percentage="levelUserPercentage.toFixed(1)"
      :showAdvHeader="validateAds()"
    >
      <template v-slot:header>
        <toolbar-core />
      </template>
      <template v-slot:body>
        <div
          class="ml-10 classContainerBodySlot"
          style="width: calc(100vw - 591px);"
        >
          <h1
            style="text-align: left;
            font: normal normal 600 24px/40px Poppins;
            letter-spacing: 0px;
            color: #101820;"
          >
            Mi perfil
          </h1>
          <p
            style="text-align: left;
            font: normal normal 300 17px/40px Poppins;
            letter-spacing: 0px;
            color: #101820;"
          >
            Panel / Mi Perfil
          </p>
          <section class="flexDefault3">
            <section class="flexDefault2">
  <div class="container-new-pill" style=" display: none; ">
    <div class="flexDefault">
      <div class="container-new-text--new">
        <span class="text-new-custom">NUEVO</span>
      </div>
      <span class="text--5min">5m. video</span>
    </div>
    <span class="container-new-pill_text-title"
      >Ejemplo de Título de Video para la plataforma</span
    >
    <span class="container-new-pill_text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
      id elit molestie, vulputate...
    </span>
    <div class="d-flex mt-4">
      <button class="btn-seeRigthNow">
        <v-icon color="white">mdi-play</v-icon>
        <span>Ver ahora</span>
      </button>
      <div class="ml-3 roundHeartIconWraper">
        <v-icon color="white">mdi-heart-outline</v-icon>
      </div>
    </div>
  </div>
  <div class="containerTabs">
    <v-tabs v-model="tab">
      <v-tab>
        <span class="pillsPendingTitle" style="text-transform: capitalize;margin-right: 10px;">Píldoras Pendientes</span> ({{ content_level_data.length }})
      </v-tab>
      <v-tab>
        <span class="pillsPendingTitle" style="text-transform: capitalize;margin-right: 10px;">Anuncios</span> ({{ appointments.length }})
      </v-tab>
    </v-tabs>

    <v-divider />

    <div v-if="tab === 0">
      <div v-for="(item, index) in content_level_data" :key="index">
        <card-list-v2
          :data="item"
          :icon="switchIcon(item)"
          :index="index"
        ></card-list-v2>
      </div>
    </div>
    <div v-if="tab === 1">
      <div v-if="isLoadingAppointments" class="text-center py-4">
        <v-progress-circular
          indeterminate
          color="primary"
          class="mr-2"
        ></v-progress-circular>
        <span class="loading-text">Cargando anuncios...</span>
      </div>
      <div v-else-if="appointments.length === 0">
        <p>No hay anuncios disponibles en este momento.</p>
      </div>
      <div v-else>
        <div v-for="(appointment, index) in appointments" :key="'app-'+index">
          <appointment-card
            :data="appointment"
            class="mb-4"
          ></appointment-card>
        </div>
      </div>
    </div>
  </div>
</section>
            <div v-if="validateAds() == true" class="container-update-plan ">
              <span class="textTitleUpdatePlan">
                Actualiza tu Plan
              </span>
              <span class="textPUpdatePlan">
                <div class="d-flex flex-column mb-4">
                  <span>Dispones de 3 DÍAS GRATIS en Pilsa </span>
                  <span>por tu Plan Gratuito. Actualiza tu</span>
                  <span>Plan para: </span>
                </div>
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <div>
                      <div class="circleIcon white-10">
                        <v-icon color="white" size="20">mdi-calendar</v-icon>
                      </div>
                    </div>
                    <div class="d-flex flex-column ml-2">
                      <span>Días</span>
                      <span class="spanBolderAdv">Ilimitados</span>
                    </div>
                  </div>
                  <div class="d-flex mt-3">
                    <div>
                      <div class="circleIcon white-10">
                        <v-icon color="white" size="20">mdi-cog</v-icon>
                      </div>
                    </div>
                    <div class="d-flex flex-column ml-2">
                      <span class="subtitle-config-adv">Ajustes</span>
                      <span class="spanBolderAdv">Personalizados</span>
                    </div>
                  </div>
                  <button
                    @click="$router.push({ name: 'checkout_auth' })"
                    class="btn-updatePlan"
                  >
                    <span>Actualizar Plan</span>
                  </button>
                </div>
              </span>
            </div>
          </section>
        </div>

        <!-- <div>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <v-card class="mt-5 mt-md-0">
                <v-img
                  :src="require('@/assets/img/Mask Group 21.png')"
                  cover
                  position="left bottom"
                  class="align-center py-15"
                  height="70px"
                >
                  <div class="d-md-flex justify-md-space-between mx-md-5">
                    <div class="d-flex justify-center mt-2 mt-md-0 py-0">
                      <v-avatar color="white" size="60">
                        <v-icon
                          v-if="
                            getAuthUser.whatsapp_account_image_profiles == null
                          "
                          color="primary"
                        >
                          mdi-account
                        </v-icon>
                        <img
                          v-else
                          :src="
                            getAuthUser.whatsapp_account_image_profiles
                              .file_upload
                          "
                          alt="profile avatar"
                        />
                      </v-avatar>
                      <div>
                        <div
                          class="
                        ml-3
                        mb-1
                        white--text
                        text-center text-regular text-capitalize
                      "
                        >
                          {{ getAuthUser.name }}
                          <span class="text-title">
                            {{ getAuthUser.last_name }}
                          </span>
                        </div>
                        <div class="ml-3">
                          <div
                            style="
                          background: white;
                          border-radius: 20px;
                          padding: 5px;
                        "
                            class="px-4"
                          >
                            <div v-if="getAuthUser.plans">
                              <div
                                v-if="
                                  getAuthUser.plans.length == 0 ||
                                    getPlansUser == undefined
                                "
                              >
                                Dispone de 3 dias gratis |
                                <a
                                  class="mt-6 mt-md-0 badge-bg-warning text-capitalize"
                                  @click="HandlerRouter('checkout_auth')"
                                  >Adquirir Plan</a
                                >
                              </div>

                              <div v-else>
                                <div v-if="!getAuthUser.company.length">
                                  {{ getPlansUser.name }}
                                </div>
                                <div v-else>
                                  Empresa:
                                  {{ getAuthUser.company[0].business_name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row mt-0 mt-md-1">
                      <div class="mx-15 mx-md-0 mt-2" flat>
                        <div>
                          <span
                            style="
                          background: #ffffff;
                          color: #5da9ff;
                          padding: 10px;
                          border-radius: 5px;
                        "
                          >
                            {{ moduleUser.name }}
                          </span>
                          <span
                            v-if="Object.values(getAuthUser).length > 0"
                            style="font-size: 12px"
                            class="text-center ml-md-4 mt-1 mt-md-3 white--text"
                          >
                            {{ getAuthUser.level.toUpperCase() | nameTilde }}
                          </span>
                        </div>
                        <div
                          style="
                        background: white;
                        border-radius: 20px;
                        padding: 5px;
                      "
                          class="px-5 mt-5"
                          v-if="
                            getAuthUser.plans &&
                              getAuthUser.plans.length > 0 &&
                              getPlansUser !== undefined &&
                              !subscriptionSoontoExpire &&
                              !expiredSubscription &&
                              !getAuthUser.company.length
                          "
                        >
                          Tu suscripción vence el:
                          <strong>{{ getPlansUser.pivot.due_date }} </strong>
                        </div>

                        <div
                          style="
                        background: white;
                        border-radius: 20px;
                        padding: 5px;
                      "
                          class="px-5 mt-5"
                          v-if="
                            expiredSubscription && !getAuthUser.company.length
                          "
                        >
                          Subscription vencida:
                          <a
                            class="mt-6 mt-md-0 badge-bg-warning text-capitalize"
                            @click="HandlerRouter('checkout_auth')"
                            >Renovar Suscripción</a
                          >
                        </div>

                        <div
                          style="
                        background: white;
                        border-radius: 20px;
                        padding: 5px;
                      "
                          class="px-5 mt-5"
                          v-if="subscriptionSoontoExpire"
                        >
                          Tu suscripción vence mañana:
                          <a
                            class="mt-6 mt-md-0 badge-bg-warning text-capitalize"
                            @click="HandlerRouter('checkout_auth')"
                            >Renovar Suscripción</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </v-img>
                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="12" md="10">
                      <div class="col-md-12 col-12">
                        <p class="text-regular-semi">Pildoras pendientes</p>
                      </div>

                      <v-row v-if="content_level_data.length != 0">
                        <v-col
                          cols="12"
                          md="11"
                          v-for="(item, index) in content_level_data"
                          :key="index"
                        >
                          <card-list-component
                            :title="item.area_name"
                            :description="item.pill_name"
                            :data="item"
                            :color="
                              item.whatsapp_accounts[0].pivot.time == 0
                                ? 'red'
                                : 'blue'
                            "
                            :index="index"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-row justify="center">
                        <v-col cols="12" md="12" class="text-center">
                          <v-progress-circular
                            :rotate="-90"
                            :size="127"
                            :width="2"
                            :value="levelUserPercentage"
                            color="#E05492"
                          >
                            <div>
                              {{ levelUserPercentage.toFixed(2) }}%
                              <br />
                              <span style="font-size: 9px"
                                >Progreso de mi recorrido</span
                              >
                            </div>
                          </v-progress-circular>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-card
                            @click="HandlerRouter('my_way')"
                            class="card-custom"
                          >
                            <div class="d-flex SSS justify-center">
                              <v-img
                                class="mt-6"
                                contain
                                max-width="25px"
                                src="@/assets/img/fi-rr-paper-plane.svg"
                              ></v-img>
                            </div>
                            <p class="text-center mt-3 mb-4">Mi recorrido</p>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" md="12">
                          <v-card
                            @click="HandlerRouter('my_profile')"
                            class="card-custom"
                          >
                            <div class="d-flex justify-center">
                              <v-img
                                class="mt-6"
                                contain
                                max-width="20px"
                                src="@/assets/img/fi-rr-user.svg"
                              ></v-img>
                            </div>
                            <p class="text-center mt-3 mb-4">Mi perfil</p>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-card
                            @click="HandlerRouter('my_favorites')"
                            class="card-custom"
                          >
                            <div class="d-flex justify-center">
                              <v-img
                                class="mt-6"
                                contain
                                max-width="20px"
                                src="@/assets/img/Icono favorito.svg"
                              ></v-img>
                            </div>
                            <p class="text-center mt-3 mb-4">
                              Pildoras favoritas
                            </p>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div> -->
      </template>
    </dashboard-new>
  </v-responsive>
</template>

<script>
import moment from "moment";
import Notification from "../Utils/notification";
import ToolbarCore from "../core/Toolbar";
import CardList from "./Components/CardList";
import Dashboard from "../Dashboard.vue";
import CardListV2 from "./Components/CardListV2.vue";
import AppointmentCard from "./Components/AppointmentCard.vue";

export default {
  components: {
    "toolbar-core": ToolbarCore,
    // eslint-disable-next-line vue/no-unused-components
    "card-list-component": CardList,
    "dashboard-new": Dashboard,
    "card-list-v2": CardListV2,
    "appointment-card": AppointmentCard,
  },
  data() {
    return {
      modules: [],
      moduleUser: [],
      moduleUserPercentage: 0,
      levelUserPercentage: 0,
      plans: "",
      plans_date: "",
      expiredSubscription: false,
      subscriptionSoontoExpire: false,
      basic: [],
      intermediate: [],
      advanced: [],
      userLevel: "",
      content_level_data: [],
      appointments: [],
      isLoadingAppointments: true,
      tab: 0,
    };
  },

  filters: {
    nameTilde(value) {
      return value == "BASICO" ? "BÁSICO" : value;
    },
  },

  created() {
    this.checkSubscriptionDate();
    this.HandlerGetModules();
    this.HandlerGetNotifications();
  },

  computed: {
    getAuthUser() {
      return this.$store.getters["auth/AUTH_USER"];
    },
    getPlansUser() {
      return this.$store.getters["auth/PLANS"];
    },
  },

  methods: {
    validateAds() {
      console.log("gets:");
      // console.log("gets:", this.getAuthUser);
      // eslint-disable-next-line no-unreachable
      if (this.getAuthUser.company.length > 0) return false;
      if (this.getAuthUser.plans.length == 0 || this.getPlansUser == undefined)
        return true;
      if (
        this.getAuthUser.plans &&
        this.getAuthUser.plans.length > 0 &&
        this.getPlansUser !== undefined &&
        !this.subscriptionSoontoExpire &&
        !this.expiredSubscription &&
        !this.getAuthUser.company.length
      )
        return `Tu Subscription vence el: ${this.getPlansUser.pivot.due_date}`;
      if (this.expiredSubscription && !this.getAuthUser.company.length)
        return "Renueva tu suscripción";
      if (this.subscriptionSoontoExpire) return "Tu suscripción vence MAÑANA";

      return this.getAuthUser.plans.length === 0 ? true : false;
    },
    switchIcon(data) {
      // const dictionary ={
      //   imagen:'mdi-'
      // }
      console.log("switch", data);
      return "mdi-play";
    },
    checkSubscriptionDate() {
      if (this.getPlansUser) {
        const expiredSubscription = moment(
          this.getPlansUser.pivot.due_date
        ).diff(moment(), "days");

        this.subscriptionSoontoExpire = expiredSubscription == 1 ? true : false;
        this.expiredSubscription = expiredSubscription < 1 ? true : false;
      }
    },
    HandlerRouter(router) {
      this.$router.push({ name: router });
    },

    async HandlerGetModules() {
      try {
        const response = await this.$store.dispatch("profile/LIST_MODULES");
        this.modules = response.data.data;
        // console.log('this.getAuthUser.module_id',this.getAuthUser.module_id)
        // console.log(' this.modules', this.modules);
        // this.moduleUser = this.modules.find(
        // async (module)  => module.id == this.getAuthUser.module_id
        //);
        this.moduleUser = this.getAuthUser.modules;
        //console.log('this.moduleUser',this.moduleUser);
        this.HandlerGetListDataMyWay(this.getAuthUser.level);
        this.HandlerGetListAppointments();
        this.HandlerGetModulePercentage(this.getAuthUser.module_id);
        this.HandlerGetLevelPercentage(
          this.getAuthUser.module_id,
          this.getAuthUser.level
        );
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },

    async HandlerGetListAppointments() {
      try {
        this.isLoadingAppointments = true;
        const response = await this.$store.dispatch(
          "profile/LIST_APPOINTMENTS"
        );

        this.appointments = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.isLoadingAppointments = false;
      }
    },

    async HandlerGetListDataMyWay(userLevel) {
      try {
        const response = await this.$store.dispatch(
          "profile/LIST_CONTENT_PENDING_CONTENT"
        );

        const content = response.data.data;

        if (content.basico != null || content.basico != undefined) {
          this.basic = content.basico;
        }
        if (content.intermedio != null || content.intermedio != undefined) {
          this.intermediate = content.intermedio;
        }
        if (content.avanzado != null || content.avanzado != undefined) {
          this.advanced = content.avanzado;
        }

        switch (userLevel) {
          case "basico":
            console.log("this.basic", this.basic);
            this.content_level_data = this.basic;
            break;
          case "intermedio":
            this.content_level_data = this.intermediate;
            break;
          case "avanzado":
            this.content_level_data = this.advanced;
            break;
          default:
            this.content_level_data = this.basic;
            break;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerGetModulePercentage(module_id) {
      try {
        const request = { module_id };
        const response = await this.$store.dispatch(
          "profile/MODULE_PERCENTAGE",
          request
        );
        this.moduleUserPercentage = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },

    async HandlerGetLevelPercentage(module_id, level) {
      try {
        const request = { module_id, level };
        const response = await this.$store.dispatch(
          "profile/LEVEL_PERCENTAGE",
          request
        );
        this.levelUserPercentage = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },

    async HandlerGetNotifications() {
      try {
        await this.$store.dispatch("profile/GET_NOTIFICATIONS");
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
}
.pillsPendingTitle-container {
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pillsPendingTitle {
  text-align: left;
  font: normal normal bold 18px/27px Poppins;

  letter-spacing: 0px;
  color: #101820;
}
.flexDefault3 {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}
.containerTabs {
  border-radius: 12px;
  /* max-width: 1070px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.spanBolderAdv {
  text-align: left;
  font: normal normal bold 16px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.btn-updatePlan {
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  width: 135px;
  height: 34px;
  display: flex;
  padding: 10.5px 0px;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.white-10 {
  background-color: #ffffff20;
}
.circleIcon {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 100px;
  padding: 5px;
  justify-content: center;
}
.textPUpdatePlan {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}
.textTitleUpdatePlan {
  text-align: left;
  font: normal normal 600 22px/40px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 0.3rem 0;
}
.container-update-plan {
  padding: 1rem;
  top: 516px;
  left: 1132px;
  width: 350px;
  display: flex;
  flex-direction: column;
  height: 325px;
  background: transparent
    linear-gradient(315deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.iconHeartcolorBorder {
  color: white;
}
.roundHeartIconWraper {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 32px;
  border: 0.5px solid #f9f9f9;
}
.btn-seeRigthNow {
  text-align: center;
  font: normal normal normal 14px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  padding: 5px 24px;
}
.text--5min {
  text-align: left;
  font: normal normal 300 10px/40px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.flexDefault {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 1rem;
}
.flexDefault2 {
  flex: 2;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.text-new-custom {
  text-align: left;
  font: normal normal normal 12px/40px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.container-new-text--new {
  background: #ffffff15 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 0px 9px;
}
.container-new-pill_text-title {
  top: 291px;
  left: 495px;
  text-align: left;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 11px 0;
}
.container-new-pill_text {
  top: 360px;
  left: 495px;
  text-align: left;
  font: normal normal 300 14px/21px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.container-new-pill {
  top: 235px;
  left: 465px;
  width: 100%;
  /* height: 262px; */
  padding: 27px;
  display: flex;
  flex-direction: column;
  background: var(--unnamed-color-101820) 0% 0% no-repeat padding-box;
  background: #101820 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.v-progress-circular {
  margin: 1rem;
}

.badge-bg-warning {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: rgba(255, 193, 7, 1) !important;
  color: 33, 37, 41;
  color: rgba(33, 37, 41, 1) !important;
}
</style>
